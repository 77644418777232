import logo from './keanu.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Keanu</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Contact (808)-383-4235 or (407)-579-8988
        </p>
      </header>
    </div>
  );
}

export default App;
